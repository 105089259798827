import React from 'react'

function AboutUs() {
    return (
                <div className='col-12 px-0 my-3'>
                    <div className=' heading text-center'>
                        <h2 className='m-0 heading-text '>O nama</h2>
                    </div>

                    <h3 style={{marginTop: '5vh'}}>Uskoro više o nama..</h3>
                </div>

                  
            
    )
}

export default AboutUs
